.loading-indicator {
  margin-left: auto;
  margin-right: auto;
  padding: 24px;
  text-align: center;
}
.custom-loading-container {
  position: fixed;
  height: 100vh;
  width: 100%;
  bottom: 0;
  right: 0;
  left: 0;
  background-color: black;
  opacity: 0.9;
  color: white;
  z-index: 9999;
}
.custom-loading-container-center {
  position: absolute;
  height: 100%;
  width: 100%;
  left: 0 !important;
  top: 0 !important;
}
.ui.dimmer > .content {
  font-size: 20px;
}

@import '../components/common/form.styles.css';
@import './index.module.css';
@import '../components/app/app-header/app-header.styles.css';
@import '../components/app/app-layout/app-layout.styles.css';
@import '../components/Tags/tags.styles.css';
@import '../components/loadingPage/loading-indicator.styles.css';
@import '../components/common/form/text.styles.css';
@import '../components/error/error.styles.css';
@import '../components/common/GridLayout/grid-layout-styles.css';
@import '../components/common/CommonImage/people.styles.css';
@import '../components/author/author-styles.css';
@import '../components/book/book-create-styles.css';
@import '../components/common/image-uploader/ImageUploader.css';
@import '../components/bookSubject/book-subject-styles.css';
@import '../components/speaker/speaker-styles.css';
@import '../components/bookChapter/add-book/add-playlist-item.styles.css';

.login-container {
  height: 100vh;
  background: linear-gradient(90.21deg, #32353f 0.17%, #60677a 56.53%);
}
.login-image-wrapper {
  display: flex;
  flex-direction: row-reverse;
  background: linear-gradient(90.21deg, #32353f 0.17%, #60677a 99.8%);
  height: 100vh;
}
.login-image {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  object-fit: cover;
  height: 95vh;
  width: 60%;
  background-image: url('../public/loginImage.png');
}
.Log-in {
  height: 100vh;
}
.form-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}
.form-logo {
  height: 10vh;
  margin-bottom: 3.5%;
}
.form-input {
  margin-top: 2.5%;
  margin-bottom: 5.5%;
  margin-left: 5%;
  margin-right: 5%;
}
.form-frame {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 350px;
  height: 45%;
  background: rgba(96, 103, 122, 0.5);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25), 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 20px;
}
.form-frame .login-input-control .ui input {
  border: 1px solid #e8e8e8;
  box-sizing: border-box;
  border-radius: 15px !important;
  width: 266px !important;
  background: rgba(96, 103, 122, 0.5) !important;
  color: white !important;
}
.form-frame .login-input-control .ui.form .field .ui.input input {
  color: white;
  background: rgba(96, 103, 122, 0.5) !important;
  border-radius: 15px !important;
}
.form-frame .login-input-control .field {
  margin: 0px 0px 0px !important;
}

.form-frame .form-button {
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.form-frame .form-button .ui.button {
  background: #525a6f;
  border-radius: 17px;
  width: 102px !important;
  height: 40px !important;
  color: #c1c1c1;
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
}

.ui.form .inline.field > label {
  color: #c1c1c1;
  margin-left: 0.4rem !important;
  margin-bottom: 0.5rem !important;
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 16px;
}
.form-title {
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 21px;
  line-height: 25px;
  color: #c1c1c1;
  margin-bottom: 3rem;
}
.checkbox-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  align-content: center;
  margin-left: 5%;
  margin-right: 5%;
  height: 5rem;
}
.checkbox-wrapper.field {
  position: relative;
  top: 12.5%;
}
.remember-me {
  color: #a7a7a7;
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 14px;
  cursor: pointer;
  width: 40%;
}

.text-input-control {
  background: #fafafa;
  border: 1px solid #cbcbcb;
  box-sizing: border-box;
  background-color: transparent;
  border: none;
}
.ui.form textarea {
  background: #fafafa;
  height: 10rem;
}

.app-layout-wrapper {
  flex-grow: 1;
  margin-left: 32px;
  margin-right: 32px;
}

.app-body {
  flex-grow: 2;
}

.app-layout-menu {
  width: 100px !important;
  height: 100% !important;
  font-family: 'Roboto', sans-serif !important;
  font-style: normal;
  font-weight: normal;
  line-height: 14px;
  color: #60677a;
}
.ui.header {
  margin: 0 !important;
}
.ui.header > .image:not(.icon),
.ui.header > img {
  width: 6.5em;
}
.ui.secondary.vertical.pointing.menu {
  position: relative;
  left: 2.5%;
  height: 60%;
  border: none;
  border-right-color: transparent !important;
}
.menu-logo {
  width: 3em;
  height: 3em;
  margin-bottom: 10% !important;
}
.ui.secondary.vertical.pointing.menu .item {
  display: flex !important;
  font-family: 'Roboto', sans-serif !important;
  font-style: normal;
  font-weight: normal !important;
  line-height: 14px;
  color: #60677a;
}
.ui.labeled.icon.menu .item > .icon:not(.dropdown) {
  font-size: 2.5rem !important;
}

.image-uploader {
  width: 100%;
  margin-bottom: 1rem;
}

.image-uploader-dropzone {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  border: 1px solid #cbcbcb;
  background-color: #fafafa;
  color: #bdbdbd;
  outline: none;
  transition: border 0.24s ease-in-out;
  margin-top: 14px;
  border-radius: 20px;
  width: 100%;
  &:focus {
    border-color: gray;
  }
}

.image-uploader-dropzone:focus {
  border-color: gray;
}
.image-uploader-dropzone:hover {
  cursor: pointer;
}
img.ui.image {
  width: auto;
  height: 50vh;
}
.ui.image {
  width: 100%;
  height: 50vh;
}
.change-button {
  background-color: #adadad;
  margin-bottom: 5%;
}
.change-button-pfg {
  background-color: #adadad;
}

.image-uploader-modal-preview-wrapper {
  display: flex;
  justify-content: center;
  padding-top: 56.25%;
  position: relative;
  width: 100%;
  overflow: hidden;
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  height: 100%;
}

.images-filters {
  margin-bottom: 2rem;
}

.image-uploader-tags-label {
  margin-bottom: 1rem;
}

.ui.image.image-uploader-modal-preview {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  border-radius: 10px;
}

.image-uploader-display-img-container {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  align-items: center;
  flex-direction: column;
}
#audioUploaderOptionsModalForm {
  .field {
    min-height: auto !important;
  }
}

#imageUploaderOptionsModalForm {
  .field {
    min-height: auto !important;
  }
}
.imageUploaderOptionsModalFormTextArea {
  .ui.form .field:last-child {
    height: 50%;
  }
  .ui.form textarea {
    height: 5vh !important;
  }
}
.image-uploader-display-preview-wrapper {
  margin-left: 16px;
  margin-right: 16px;
  width: 20vw;
  height: 35vh;
  position: relative;
  overflow: hidden;
  border-radius: 10px;
  img.ui.image {
    width: 100%;
    height: 35vh;
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    border-radius: 10px;
  }
}

.image-uploader-display-preview {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.image-uploader-display-img-wrapper {
  margin-left: 16px;
  margin-right: 16px;
  width: 20vw;
  position: relative;
  overflow: hidden;
  img.ui.image {
    width: 100%;
    height: 35vh;
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    border-radius: 10px;
  }
}

.existing-images-image {
  width: 100% !important;
}

.ui.button.existing-images-button {
  border: 0;
  padding: 0;
  background: none;
  width: calc(25% - 8px);
  margin: 4px;
}

.existing-images-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-left: -4px;
  margin-right: -4px;
  margin-bottom: 16px;
  max-height: 60vh;
  overflow: auto;
}
.audio-uploader-display-img-container {
  height: 20vh;
}
.audio-uploader-display-preview-wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 20vh;
}
.audio-uploader-display-text-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-content: center;
  align-items: center;
  align-self: center;
  font-weight: bold;
  font-size: 16px;
  font-family: 'Open Sans', sans-serif;
}
.audio-icon {
  width: 50px;
  height: 50px;
}
.audio-button-wrapper {
  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: center;
  align-self: center;
  margin-bottom: 2.5%;
}

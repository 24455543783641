.text-input {
  min-width: 17em;
  margin: 0em !important;
}

.text-field {
  min-width: 22em;
  margin: 0em !important;
}

.percentage {
  min-width: 12em;
}

.form-dropdown {
  min-width: 12em !important;
}

.warning .ui.form .field .ui.input input {
  background-color: yellowgreen;
}

.form-element-container {
  display: flex;
  flex-direction: row;
  margin: 1rem;
}

.toggle-container {
  margin: 0 !important;
}

.toggle-segment {
  display: flex;
  justify-content: center;
  align-items: center;
}

.toggle-text-container {
  margin-left: 1rem !important;
}

.password-control-show-btn {
  position: absolute;
  right: 0;
  height: 100%;
  background: none;
  border: 0;
  color: var(--muted-text-color);
}
.password-control-show-btn:focus {
  outline: 0;
}

.ui.label {
  background-color: rgb(77, 113, 152);
  color: white;
}

.label-colored {
  background-color: rgb(77, 113, 152) !important;
  color: white;
}

.ui.table thead th {
  background-color: rgb(77, 113, 152) !important;
  color: white;
}

.form-element-container.relative {
  position: relative;
  width: 100%;
  margin-left: -14px;
}

.form-element-container.adjoining {
  position: relative;
  width: 100%;
  margin-left: -48px;
}

.bottom {
  position: absolute;
  bottom: 0px;
}

.form-dropdown.bottom {
  min-width: 120px !important;
}

.form-element-group-container {
  display: flex !important;
  flex-direction: row;
}

div[name^='selection_'],
div[name^='annualCapsType'] {
  border-bottom-left-radius: 0px !important;
  border-top-left-radius: 0px !important;
}

.no-radius-label {
  border-radius: 0px !important;
}

.ui.form .field .ui.input .login-input-control .ui input {
  border: 1px solid #e8e8e8;
  box-sizing: border-box;
  border-radius: 15px !important;
  width: 266px !important;
  background: rgba(96, 103, 122, 0.5) !important;
  color: white;
}
.ui.form .field .ui.input .login-input-control .ui.form .field .ui.input input {
  color: white;
  background: rgba(96, 103, 122, 0.5) !important;
  border-radius: 15px !important;
}
.ui.form .field .ui.input .login-input-control .field {
  margin: 0px 0px 0px !important;
}

.grid-list {
  border: none !important;
  box-shadow: none !important;
}
.grid-list-columns {
  display: grid;
  grid-template-columns: 33% 33% 33%;
  justify-content: center;
}
.grid-container {
  display: flex;
  justify-content: center;
}
.grid-card-frame {
  width: 95.5% !important;
  margin: 0.4rem !important;
  background-color: #f9f9f9 !important;
}
.gird-card-grid {
  display: grid;
  grid-template-columns: 50% 50%;
  height: 205px;
  margin: 3%;
  border: none;
}
.grid-card-image {
  margin-right: 5%;
}
.grid-card-content {
  height: 195px;
}
.grid-card-text-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.grid-card-text {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  margin-left: 10%;
}
.grid-card-name {
  font-family: 'Open Sans', sans-serif;
  font-weight: 600;
  font-size: 15px;
  line-height: 20px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.grid-card-author {
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 16px;
  margin-top: 10%;
}
.grid-card-duration {
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  margin-top: 10%;
}
.grid-card-chapter {
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  margin-top: 5%;
}
.grid-card-reader {
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  margin-top: 5%;
}
.grid-card-description {
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  margin-top: 5%;
  height: 5vh;
}
.grid-button-container {
  width: 47.5%;
  display: flex;
  flex-direction: row;
  margin-left: 1%;
  position: absolute;
  bottom: 5%;
}

.ui.buttons:not(.basic):not(.inverted) > .button,
.ui.buttons > .ui.button:not(.basic):not(.inverted) {
  width: 20% !important;
}
.ui.buttons:not(.basic):not(.inverted) {
  width: 100% !important;
}
.ui button .grid-card-delete {
  text-align: left;
}
.ui.buttons {
  button.ui.grid-card-delete {
    width: 100% !important;
  }
}

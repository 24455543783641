.error-grid {
  display: grid;
  grid-template-columns: 10% 70%;
}
.error-404icon {
  width: 70px;
  height: 50px;
}
.error-text-wrapper {
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.error-text1 {
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 16px;
  margin-bottom: 2.5%;
}
.error-text2 {
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
}

.book-create-form-button {
  margin-top: 5%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}
.book-create-form-button .ui.button {
  background-color: #000000 !important;
}
.book-create-input-wrapper {
  width: 100%;
  display: grid;
  grid-template-columns: 50% 50%;
}
.book-create-input {
  width: 100%;
}
.book-create-input .ant-picker-large {
  width: 100%;
  padding: 6.5px 6.5% 6.5px 0px;
  outline: none;
  margin-bottom: 4%;
}
.book-create-input .ant-picker-large .ant-picker-input > input {
  height: 6vh;
}
.book-create-input .ant-picker {
  border: none;
  outline: none;
}
.book-create-input .ant-picker:hover,
.book-create-input .ant-picker-focused {
  border-color: none;
  outline: none;
  -webkit-box-shadow: none;
}

.book-create-multiple-select {
  margin-bottom: 5%;
}
.ui.form .inline.field:not(.wide) .ui.input,
.ui.form .inline.fields .field:not(.wide) .ui.input {
  width: 90%;
  height: 3.5rem;
  border: none;
  border-radius: 0.28571429rem;
}

.ui.multiple.search.dropdown,
.ui.multiple.search.dropdown > input.search {
  width: 90% !important;
}
.ui.vertical.segment:last-child {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  height: 3.4rem;
}
.book-create-show-fav {
  border: 1px solid rgba(34, 36, 38, 0.15) !important;
  box-shadow: 0 1px 2px 0 rgba(34, 36, 38, 0.15) !important;
  border-radius: 0.28571429rem;
}
.book-create-right {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 1rem 0 1rem;
}
.book-list {
  border: none !important;
  box-shadow: none !important;
}

.book-card-description {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.book-card-name {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.book-card-name {
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 25px;
  font-size: 18px;
}
.ui.card .content img,
.ui.cards > .card .content img {
  height: 100%;
  margin: 1%;
  border-radius: 15px;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}
.book-card-text-wrapper {
  height: 80%;
}
.button-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  margin-left: 5%;
}
.book-card-text {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  height: 80%;
}
.ui.buttons .button:last-child {
  background-color: #d6d6d6;
}
.ui.buttons .button:first-child {
  display: flex;
  flex-direction: row-reverse;
  justify-content: center;
  width: 50%;
  background-color: #60687a !important;
  color: #ffffff;
}

.ui.card,
.ui.cards > .card {
  border: none;
  box-shadow: none;
}

.book-create-label {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 90%;
  color: #b8b6b6;
}
.category-create-button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-content: center;
  color: #b8b6b6;
  margin-bottom: 0.5rem;
  cursor: pointer;
}
.ui.form .field .ui.input input,
.ui.form .fields .field .ui.input input {
  background: #fafafa;
  border: 1px solid #cbcbcb;
}
.ui.form .field > .selection.dropdown {
  background: #fafafa;
  border: 1px solid #cbcbcb;
}
.book-create-isShown {
  margin-bottom: 4%;
}

.ui.checkbox input.hidden + label {
  color: #b8b6b6;
}
.book-card-create {
  color: #ffffff !important;
}
.ui.card > :only-child {
  height: 100%;
}
.create-button-container {
  display: flex;
  flex-direction: row-reverse;
}
.create-create-button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  color: #b8b6b6;
  margin-left: 1rem;
  margin-top: 5%;
}
.ui.form .field > .selection.dropdown {
  width: 90% !important;
}

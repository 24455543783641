.ui .segment {
  background-color: transparent;
}
.app-header {
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-top: 24px;
  margin-bottom: 24px;
}
.app-header-user {
  margin-right: 8px;
}
.app-header-right {
  width: 100%;
  text-align: center;
  font-size: 24px;
  margin-right: 5%;
}
.create-button {
  display: flex;
  flex-direction: row-reverse;
}
.ui.header > .image:not(.icon):only-child,
.ui.header > img:only-child {
  width: 30%;
}
.logo {
  margin-left: 12.5% !important;
}

.sign-out-button {
  margin-right: 2rem;
}

.welcome {
  width: 300px;
  height: 300px;
  font-size: 22px;
  margin: 1rem;
  background: #efefef;
  display: flex !important;
  flex-direction: column !important;
  justify-content: center !important;
  cursor: pointer;
  background: #60677a;
  border-radius: 20px !important;
}
.text-wrapper {
  border: solid 1px;
  width: 100%;
}
.home-page-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-self: center;
  align-items: center;
  align-content: center;
}
.home-page-text {
  margin-top: 2rem;
  text-align: center;
  font-family: 'Roboto', sans-serif;
  font-size: 21px;
  line-height: 25px;
  color: #ffffff;
}
.home-icon {
  width: 100%;
  height: 60px;
}
.ui.grid {
}

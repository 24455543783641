.playlist-items-add-item {
  width: 30rem;
  height: 3rem;
}
.playlist-items-add-item i.icon {
  background: #676e80;
  border-radius: 15px !important;
}
.playlist-items-add-item .ui.search {
  width: 30rem;
  height: 3rem;
}
.playlist-items-add-item .ui.search .ui.input {
  width: 30rem;
  height: 3rem;
}
.playlist-items-add-item .ui.search .ui.input .ui.icon.input > input {
  width: 30rem;
  height: 3rem;
  min-height: 1rem;
  line-height: 1rem;
}

.playlist-items-add-item .ui.search .prompt {
  border-radius: 15px;
}

.add-playlist-item-search-desc {
  height: 3rem;
}
.ui.search > .results {
  width: 30rem !important;
}
.chapter-input-wrapper {
  width: 30%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}
.chapter-input {
  margin-bottom: 5%;
  color: #b8b6b6;
}
.chapter-table-container {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
}

.chapter-create-button-container {
  margin-left: 1rem;
  display: flex;
  align-items: center;
  text-align: center;
  color: #b8b6b6;
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 14px;
  border-radius: 10px;
}
.chapter-create-button-container .ui.button {
  display: flex;
  align-items: center;
  text-align: center;
  color: #b8b6b6;
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 14px;
  border-radius: 10px;
}
.chapter-create-button {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  text-align: center;
  width: 130px;
  cursor: pointer;
}
.chapter-table-columns {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  border: none !important;
  box-shadow: none !important;
}
.chapter-table-columns .ui.table {
  border-radius: 10px;
}
.chapter-table-columns .ui.table thead tr:first-child > th:last-child {
  border-radius: 10px;
}
.chapter-table-columns .ui.table thead tr:first-child > th:first-child {
  border-radius: 10px;
}
.chapter-table-rows {
  flex-direction: row;
  justify-content: space-around;
  border: none !important;
  box-shadow: none !important;
}
.chapter-container {
  display: flex;
  justify-content: center;
}

.ui.celled.table tr td,
.ui.celled.table tr th {
  width: 20.5rem !important;
}
.ui.celled.table tr td:first-child,
.ui.celled.table tr th:first-child {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.play-button {
  margin-right: 5%;
  cursor: pointer;
}
.right {
  display: flex;
  flex-direction: row-reverse;
  justify-content: right;
  align-items: center;
}
.ui.celled.table tr td,
.ui.celled.table tr th {
  border: none !important;
}
.close-icon-container {
  width: 100%;
  height: 2rem;
  display: flex;
  flex-direction: row-reverse;
}
.close-icon-container i.icon {
  background-color: transparent;
  margin: 2%;
  cursor: pointer;
}

.book-chapter-add-chapter-container {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}
.ui.secondary.button,
.ui.secondary.buttons .button {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.book-chapter-input {
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}
.book-chapter {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 3%;
}

.chapter-card-frame {
  width: 100% !important;
  margin-bottom: 1rem !important;
  margin-top: 1rem !important;
  background-color: #f9f9f9 !important;
  border: none !important;
}
.chapter-card-grid {
  display: grid;
  grid-template-columns: 30% 70%;
  height: 250px;
  margin: 3%;
  border: none !important;
}
.chapter-card-content {
  height: 100%;
}
